import React from 'react'
import './Header.css';

const Header = () => {
  return (<>
    <div className="header">
      <div className="header-item logo"><img alt="logo" src="https://content.provengo.tech/images/Provengo-site-logo.png" /></div>
      <div className="header-item text">Provengo<i><b>Bank</b></i></div>
    </div>
  </>

  )
}

export default Header