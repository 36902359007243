import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom";

import Welcome from '../../components/welcome/Welcome'
import { UserContext } from '../../contexts/UserContext';
import SetTimeAndBranch from '../setTimeAndBranch/SetTimeAndBranch';
import timeSlots from "../../files/timeSlots.json";
import "./SetTime.css";
import { ServiceContext } from '../../contexts/ServiceContext';
import Scheduler from '../../components/scheduler/Scheduler';


const SetTime = () => {
    const navigate = useNavigate();

    const { username } = useContext(UserContext);
    const { service, topic, time, setTime, branch, setBranch } = useContext(ServiceContext);

    const handleChooseHour = (e) => {
        setTime(e.target.value)
    }

    const isRadioSelected = (value) => {
        return value === time;
    }

    const handleContinue = (e) => {
        e.preventDefault();
        navigate("/contactAndRemarks")
    }
    
    return (
        <div className="page-container">
            <div className="welcome-container">
                <Welcome username={username} />
                <span> {`You are setting up a meeting with `}<span id="service">{service}</span> about <span id="topic">{topic}.</span></span>
            </div>
            <div className="select-time"><b>Please select a time slot for your meeting</b></div>
            
            <div className="section">
                <Scheduler time={"Morning"} timeSlots={timeSlots} handleContinue={handleContinue} handleChooseHour={handleChooseHour} isRadioSelected={isRadioSelected} />
                <Scheduler time={"Afternoon"} timeSlots={timeSlots} handleContinue={handleContinue} handleChooseHour={handleChooseHour} isRadioSelected={isRadioSelected} />
            </div>

            {(service === "Cashier") && <SetTimeAndBranch selectedBranch={branch} setSelectedBranch={setBranch} />}
            <div className={service !== "Cashier" ? "buttons-container btns-remarks" : " buttons-container"}>
                <button id="set-time-back-button" className="btn btn-back" onClick={() => navigate(-1, { state: { username } })}>back</button>

                {service !== "Cashier" ?
                    <button id="set-time-continue-button" className="btn btn-secondary btn-continue" disabled={time ? false : true} onClick={handleContinue}>continue</button> :
                    <button id="set-time-continue-button" className="btn btn-secondary btn-continue" disabled={time && branch ? false : true} onClick={handleContinue}>continue</button>
                }

            </div>

        </div>
    )
}

export default SetTime